import React, { useEffect, useState } from "react";
import ApiClient from "../apimethods/apiClient";
import loader from "../components/loader/loader";

export default function RefundModal({
  setShow,
  show,
  handlePayWithCard,
  marchantId,
  setHsnNo,
}) {
  const [ShowModal, setShowModal] = useState("none");
  const [savedDeviceId, setSavedDeviceId] = useState(null);
  const [data, setData] = useState([]);
  useEffect(() => {
    setShowModal(show);
  }, [show]);

  useEffect(() => {
    getAllDevices();
  }, []);

  const getAllDevices = () => {
    let url = `devices?user_id=${marchantId}`;
    loader(true);
    ApiClient.get(url).then((res) => {
      if (res.success) {
        loader(false);
        const data = res.data;
        setData(data);
      }
    });
  };
  const handleSelectDevice = (item) => {
    setSavedDeviceId(item?._id);
    setHsnNo(item?.hsn)
  };
 
  return (
    <div>
      <div
        class={`modal delete-modal  d-${show}`}
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content p-0">
            <div class="modal-header py-2 border-0">
              <button
                type="button"
                class="close pb-0"
                onClick={(e) => setShow("none")}
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body text-center p-2">
              <h5 className=" mt-3 mb-4 paynowText mx-4">Select Device</h5>
            </div>
            <div class="text-center px-4 pb-4">
              {data?.length > 0 ? (
                <div className="saved_addres">
                  <div className="inner_listingadd">
                    {data.map((item, index) => (
                      <div className="addes_save d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-cessnter">
                          <input
                            type="radio"
                            name="deviceselect"
                            checked={item?._id === savedDeviceId}
                            onChange={(e) => {
                              setSavedDeviceId(item?._id);
                              handleSelectDevice(item);
                            }}
                            className="radio_custom mr-2 mt-2"
                          />
                          <div className="d-flex justify-content-between">
                            <div className="text_addsave">
                              <h4 className="text-capitalize">
                                {item?.deviceName}
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : null}
              <button
                type="button"
                onClick={(e) => handlePayWithCard()}
                class="btn btn-primary w-100 mt-3"
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
