import React, { useEffect, useState } from "react";
import "./style.scss"; 
import { useNavigate } from "react-router-dom";
import ApiClient from "../../apimethods/apiClient";
import WebsiteHeader from "../../components/global/websiteheader/WebsiteHeader";
import loader from "../../components/loader/loader";
import { toast } from "react-toastify";
import Footer from "../../components/global/footer/Footer";

const ContactUs = () => {
  const [cms, setcms] = useState();
  const history = useNavigate();
  const [form, setForm] = useState({
    name: "",
    email: "",
    title: "",
    message: "",
  });
  const [errors, setErrors] = useState({
    name: "",
    email: "",
    title: "",
    message: "",
  });

  useEffect(() => {
    getCMS();
  }, []);

  const getCMS = () => {
    ApiClient.get(`cms/listing`).then((res) => {
      if (res.success) {
        setcms(res.data);
      }
    });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!form.name) newErrors.name = " Name is required";
    if (!form.email) newErrors.email = "Email is required";
    else if (!/\S+@\S+\.\S+/.test(form.email))
      newErrors.email = "Please enter a valid email";
    if (!form.title) newErrors.title = "Subject is required";
    if (!form.message) newErrors.message = "Message is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const payload = { ...form };
      try {
        loader(true);
        const res = await ApiClient.post("contact", payload);
        if (res.success) {
          toast?.success(res?.message);
          loader(false);
          setForm({ ...form, name: "", title: "", email: "", message: "" });
        } else {
          console.error("Error:", res);
        }
      } catch (error) {
        console.error("API call failed:", error);
      }
    }
  };

  return (
    <>
      <div className="other-header">
        <WebsiteHeader />
      </div>
      <section className="contact-us-page">
        <div className="features_hero ">
          <img src="/assets/img/contact-us.webp" className="features_img" />
          <div className="container d-flex justify-content-center align-items-center h-100 ">
            <div className="feature_parent consPa flex-column w-25 text-center border-0">
              {" "}
              <h3 className="feature_heading">Contact US</h3>
              <p className="text-white">Please fill the details</p>
            </div>
          </div>
        </div>
        <div className="address-fields">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-12 mb-3">
                <div className="address-inner">
                  <div className="address-icon">
                    <img src="/assets/img/phone.svg" />
                  </div>
                  <div>
                    <h3> Call Us</h3>
                    <p>0172 5087346</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12 mb-3">
                <div className="address-inner">
                  <div className="address-icon">
                    <img src="/assets/img/loc.svg" />
                  </div>
                  <div>
                    <h3> Address</h3>
                    <p>Lorem ipsum</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12 mb-3">
                <div className="address-inner">
                  <div className="address-icon">
                    <img src="/assets/img/mail.svg" />
                  </div>
                  <div>
                    <h3> Email</h3>
                    <p>abc@gmail.com</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="contact-us-title">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="contact-us-title-inner">
                  <h4>HAVE QUESTION? WRITE A MESSAGE</h4>
                  <p>We will catch you as early as we receive the message</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="contact-us-inner">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label for="name">Name</label>
                        <input
                          type="text"
                          id="name"
                          name="name"
                          className="form-control"
                          onChange={(e) =>
                            setForm({ ...form, name: e?.target?.value })
                          }
                          value={form?.name}
                        />
                        {errors.name && (
                          <div className="invalid-feedback text-red-500 d-block  ">
                            {errors.name}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="form-group">
                        <label for="email">Email</label>
                        <input
                          id="email"
                          name="email"
                          className="form-control"
                          onChange={(e) =>
                            setForm({ ...form, email: e?.target?.value })
                          }
                          value={form?.email}
                        />
                        {errors.email && (
                          <div className="invalid-feedback text-red-500 d-block  ">
                            {errors.email}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="form-group">
                        <label for="text">Subject</label>
                        <input
                          type="text"
                          id="text"
                          name="title"
                          className="form-control" 
                          onChange={(e) =>
                            setForm({ ...form, title: e?.target?.value })
                          }
                          value={form?.title}
                        />
                        {errors.title && (
                          <div className="invalid-feedback text-red-500 d-block  ">
                            {errors.title}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="col-lg-12">
                      <div className="form-group">
                        <label for="message">Message</label>
                        <textarea
                          id="message"
                          name="message"
                          rows="4"
                          className="form-control"
                          // required
                          onChange={(e) =>
                            setForm({ ...form, message: e?.target?.value })
                          }
                          value={form?.message}
                        ></textarea>
                        {errors.message && (
                          <div className="invalid-feedback text-red-500 d-block  ">
                            {errors.message}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="text-center mt-2">
                    <button type="submit" className="btn dark-btn">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section> 
      <Footer />
    </>
  );
};

export default ContactUs;
